import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Auth0ProviderWithHistory from './components/auth/auth0-provider-with-history'
import { CSpinner } from '@coreui/react-pro'
import ErrorBoundary from 'src/components/errorHandling/ErrorBoundary'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

class App extends Component {
  render() {
    return (
      <Router>
        <ErrorBoundary>
          <Auth0ProviderWithHistory>
            <Suspense fallback={loading}>
              <Routes>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </Auth0ProviderWithHistory>
        </ErrorBoundary>
      </Router>
    )
  }
}

export default App
