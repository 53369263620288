import { LogglyTracker } from 'loggly-jslogger'

const logger = new LogglyTracker()

logger.push({
  logglyKey: '85a047b8-b4c1-4944-8b59-813eac86a308',
  logFromConsole: true,
  tag: 'coffeesuite-client',
})

export default logger
