import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logger from 'src/components/logging/Logger'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // log the error to loggly
    logger.push({ error, info })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h3>Please wait...</h3>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
}
export default ErrorBoundary
