import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithHistory = (props) => {
  const children = props.children
  const domain = 'coffeesuite.eu.auth0.com'
  const clientId = process.env.REACT_APP_JwtBearerClientId
  const audience = process.env.REACT_APP_JwtBearerAudience
  const history = useNavigate()

  const onRedirectCallback = (appState) => {
    history((appState && appState.returnTo) || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.object,
}
export default Auth0ProviderWithHistory
